'use client'
import { useEffect } from 'react'
import useAuth from '@/store/auth'
import useAppointment from '@/store/appointment'
import { useRouter, usePathname } from '@/navigation'
import { useReCaptcha } from 'next-recaptcha-v3'
import { enqueueSnackbar } from 'notistack'
import { useLocale, useTranslations } from 'next-intl'
import { Link } from '@/navigation'

export default function CCP_AppointmentUserHNProvider({ children }) {
  const { userMe, isUserMeLoading } = useAuth()
  const locale = useLocale()
  const __t = useTranslations()
  const router = useRouter()
  const pathname = usePathname()
  const { getUserHN, isRealtime, realtimeForm } = useAppointment()
  const autoRedirectPages = ['/appointment/step2', '/appointment/step3']
  const pathnameContainsOneOfAutoRedirectPages = autoRedirectPages.some(
    (page) => pathname.includes(page)
  )
  const hn = realtimeForm?.hn
  const { executeRecaptcha, loaded: isRecaptchaLoaded } = useReCaptcha()

  const shouldRedirectIfNoUser =
    pathnameContainsOneOfAutoRedirectPages &&
    isRealtime &&
    !userMe &&
    !isUserMeLoading

  useEffect(() => {
    if (hn) {
      return
    }
    const fetchUserHN = async () => {
      if (userMe && isRecaptchaLoaded) {
        const token = await executeRecaptcha('get_user_hn')
        const res = await getUserHN(userMe, token, locale)
        let errorMessage = res?.ResponseStatus?.Description || res?.error
        if (errorMessage) {
          if (errorMessage?.length < 2) {
            errorMessage = ''
          }
          const message = (
            <div>
              {locale === 'th'
                ? ' ไม่สามารถสร้าง HN ได้ กรุณาตรวจสอบที่'
                : 'Error found when getting your Hospital No. Please check at'}
              <Link
                href="/my-account/profile"
                className="tw-inline-block tw-mx-1 tw-border tw-border-white/30 tw-py-0.5 tw-px-1 tw-rounded"
              >
                {__t('my-profile')}
              </Link>
              ☝️ {errorMessage}
            </div>
          )

          enqueueSnackbar(message, {
            autoHideDuration: 9000,
          })
          //if current page is step 2 or step 3, redirect to step 1
          if (pathnameContainsOneOfAutoRedirectPages && isRealtime) {
            router.push('/my-account/profile')
          }
        }
      }
    }
    fetchUserHN()
  }, [userMe, isRecaptchaLoaded, getUserHN, hn])

  useEffect(() => {
    // Check if userMe has no data and is not loading
    if (shouldRedirectIfNoUser) {
      // Redirect to the login page with the next parameter as the current path and searchParams
      const currentPathAndSearch = `${locale == 'th' ? '' : `/${locale}`}${pathname}${window.location.search}`
      router.push(
        `/auth/login?destination=${encodeURIComponent(currentPathAndSearch)}`
      )
    }
  }, [shouldRedirectIfNoUser, pathname])

  return (
    <>
      {children}
      {/* {JSON.stringify(useAppointment.getState().userHN)} */}
    </>
  )
}
